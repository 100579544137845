import * as React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export function useAnalytics() {
  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID!);
    setInitialized(true);
  }, []);

  return {
    initialized,
  };
}

interface AnalyticsWrapperProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}

export function AnalyticsWrapper(props: AnalyticsWrapperProps) {
  const location = useLocation();

  React.useEffect(() => {
    if (props.initialized) {
      ReactGA.pageview(location.pathname + location.hash + location.search);
    }
  }, [props.initialized, location]);

  return props.children;
}
