import type { IUser as IMe } from '../../../useUser';
import type { IUser } from '../../../Leaderboard/useLeaderboard';

import {
  CrownIcon,
  FirstRankFrame,
  NormalRankFrame,
  MyScoreFrame,
  UserSkeleton,
  UserProfileFrame,
} from '../../../../assets/images';

import { classNames, format } from '../../../../lib';

interface RowProps {
  user: IUser;
  rank?: number;
}

interface MeProps {
  me: IMe;
  ranking: number;
  score: number;
}

interface SkeletonProps {
  index: number;
}

const FirstRank = (props: RowProps) => {
  const { user } = props;

  return (
    <div className="border-cut-top flex bg-[rgb(51,51,51)] pt-6 pb-4 justify-center items-center">
      <span className="text-6xl font-heavent font-bold text-[rgb(255,186,13)] italic">
        #1
      </span>
      <div className="flex items-center">
        <div className="relative ml-6 mr-4">
          <div className="w-10 h-10 relative z-10">
            <img alt="first-rank" src={user.profile_picture} />
          </div>
          <div className="absolute -top-1 -left-1" style={{ width: '3rem' }}>
            <img alt="user-profile-frame" src={FirstRankFrame} />
          </div>
          <div
            className="absolute w-10 -top-6 left-1/2"
            style={{ transform: 'translate(-50%,0)' }}
          >
            <img alt="crown-icon" src={CrownIcon} />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-[rgb(255,186,13)] text-xl max-w-[8rem] overflow-hidden text-ellipsis">
            {user.full_name}
          </span>
          <span className="text-[rgb(255,186,13)] text-xl font-taxi text-left">
            {format(user.score)}
          </span>
        </div>
      </div>
    </div>
  );
};

const FirstRankList = (props: RowProps) => {
  const { user } = props;

  return (
    <div
      className={classNames([
        'border-cut-top w-full bg-[rgb(177,133,29)] border-b-[1px] border-black pt-1',
        'xs:pt-0',
      ])}
    >
      <div className="flex items-center justify-center italic gap-4 py-2 px-4">
        <span className="w-14 text-center">#1</span>
        <div
          className="relative w-10 p-0.5 bg-cover"
          style={{
            background: `url(${FirstRankFrame})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="w-full relative z-10">
            <img
              className="w-full h-full rounded"
              src={user.profile_picture}
              alt={`rank-${user.full_name}-profile`}
            />
          </div>
          <div
            className="absolute w-3 -top-2 left-1/2"
            style={{ transform: 'translate(-50%,0)' }}
          >
            <img alt="crown-icon" src={CrownIcon} />
          </div>
        </div>
        <strong className="w-48 whitespace-nowrap overflow-hidden text-ellipsis">
          {user.full_name}
        </strong>
        <span className="w-20 font-taxi">{format(user.score)}</span>
      </div>
    </div>
  );
};

const Row = (props: RowProps) => {
  const { user, rank } = props;

  return (
    <div className="row w-full">
      <div className="flex items-center justify-center italic gap-4 py-2 px-4">
        <span className="w-14 text-center">#{rank}</span>
        <div
          className="relative w-10 p-0.5"
          style={{
            background: `url(${NormalRankFrame})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="w-full relative z-10">
            <img
              className="w-full h-full rounded"
              src={user.profile_picture}
              alt={`rank-${user.full_name}-profile`}
            />
          </div>
        </div>
        <strong className="w-48 whitespace-nowrap overflow-hidden text-ellipsis">
          {user.full_name}
        </strong>
        <span className="w-20 font-taxi">{format(user.score)}</span>
      </div>
    </div>
  );
};

const Me = (props: MeProps) => {
  const { me, ranking, score } = props;

  return (
    <div className="row w-full relative">
      <div className="absolute w-full h-full">
        <img
          className="w-full h-full"
          alt="my-score-frame"
          src={MyScoreFrame}
        />
      </div>
      <div className="flex items-center justify-center italic gap-4 py-2 px-4 relative z-10">
        <span className="w-14 text-center">
          {ranking ? `#${ranking}` : '-'}
        </span>
        <div
          className="relative w-10 p-0.5"
          style={{
            background: `url(${UserProfileFrame})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="w-full relative z-10">
            <img
              className="w-full h-full rounded"
              src={me.profilePicture}
              alt={`rank-${me.name}-profile`}
            />
          </div>
        </div>
        <strong className="w-48 text-xl whitespace-nowrap overflow-hidden text-ellipsis">
          {me.name}
        </strong>
        <span className="w-20 font-taxi">{format(score)}</span>
      </div>
    </div>
  );
};

const Skeleton = (props: SkeletonProps) => {
  const { index } = props;

  return (
    <div className="row w-full">
      <div className="flex items-center justify-center italic gap-4 py-2 px-4">
        <span className="w-14 text-center text-[rgb(71,71,71)]">#{index}</span>
        <div
          className="relative w-10 p-0.5"
          style={{
            background: `url(${NormalRankFrame})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="w-full z-10">
            <img
              className="w-full h-full rounded opacity-50"
              src={UserSkeleton}
              alt={`skeleton-user-${index}`}
            />
          </div>
        </div>
        <strong className="w-48 whitespace-nowrap overflow-hidden text-ellipsis text-[rgb(71,71,71)]">
          Name
        </strong>
        <span className="w-20 font-taxi text-[rgb(71,71,71)]">8:88.888</span>
      </div>
    </div>
  );
};

export { FirstRank, FirstRankList, Row, Me, Skeleton };
