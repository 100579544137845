import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

interface TermsAndConditionsProps {
  href: string;
}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { href } = props;
  const navigate = useNavigate();

  const onClick = () => {
    ReactGA.event({
      category: 'engagement',
      action: 'view_term_and_conditions',
    });
    navigate(href, { replace: true });
  };

  return (
    <button
      className="text-center text-sm text-[rgb(130,130,130)] underline"
      onClick={onClick}
    >
      Terms & Conditions
    </button>
  );
};

export default TermsAndConditions;
