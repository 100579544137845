import { useNavigate, useLocation } from 'react-router-dom';

import { APLogo as Logo } from '../assets/images';

import { classNames } from '../lib';

interface APLogoProps {
  disabled?: boolean;
}

const APLogo = (props: APLogoProps) => {
  const { disabled } = props;
  const navigate = useNavigate();
  const location = useLocation()

  return (
    <div
      className={classNames([
        'absolute right-4 top-4 w-10 z-20 cursor-pointer',
        'xs:w-6',
        'lg:w-8',
      ])}
      onClick={() => (disabled ? {} : location.pathname === '/' ? navigate(0) : navigate('/', { replace: true }))}
    >
      <img alt="ap-logo" src={Logo} />
    </div>
  );
};

export default APLogo;
