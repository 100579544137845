import { useLayoutEffect, useState } from 'react';

import type { Auth } from '../../../App';

import {
  APLogo,
  TermsAndConditions,
  Wallpaper,
  Spotlight,
  LeaderBoardButton,
} from '../../../component';
import { classNames } from '../../../lib';
import {
  CoinBackground,
  FacebookIcon,
  CampaignLogo,
  WEBP,
} from '../../../assets/images';

interface LandingProps {
  auth: Auth;
}

const Landing = (props: LandingProps) => {
  const { auth } = props;

  const [isBlur, setIsBlur] = useState<boolean>(false);
  const isLogin = !(auth.status !== 'connected');

  useLayoutEffect(() => {
    setTimeout(() => setIsBlur(true), 3000);
  }, []);

  const saveUser = async (accessToken: string) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_API + '/authentication/authenticate',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessToken }),
        },
      );
      const result = await response.json();

      localStorage.setItem('user', JSON.stringify(result));
      window.location.assign('/leaderboard');
    } catch (e) {
      console.error('❗', e);
    }
  };

  const onClick = () => {
    window.location.assign('/leaderboard');
  };

  const onLogin = () => {
    window.FB.login(
      response => {
        if (response.status === 'connected') {
          saveUser(response.authResponse.accessToken);
        }
      },
      { scope: 'public_profile,email' },
    );
  };

  return (
    <>
      <Wallpaper isBlur={isBlur} />
      <APLogo />
      <div
        className={classNames([
          'z-20 absolute opacity-0',
          'xs:z-20 absolute opacity-0 right-12 top-40 w-52 animate-logo-from-right-xs-md',
          'md:z-20 absolute opacity-0 right-12 top-40 w-6/12 animate-logo-from-right-xs-md',
          'lg:z-20 absolute opacity-0 right-12 top-40 w-52 animate-logo-from-right-xs-md',
        ])}
      >
        <picture>
          <source srcSet={WEBP.campaignLogo} />
          <img alt="campaign-logo" src={CampaignLogo} />
        </picture>
      </div>
      <Spotlight />
      {isBlur && (
        <div
          className={classNames([
            'w-full h-full bg-[rgba(0,0,0,0.6)] relative z-20 pt-24 pb-10',
            'xs:py-10 xs:h-screen',
            'lg:rounded-[40px]',
          ])}
          style={{
            backgroundImage: `url(${CoinBackground})`,
            backgroundSize: 'contain',
          }}
        >
          <APLogo />
          <div
            className={classNames([
              'w-4/5 h-full flex flex-col justify-center items-center mx-auto',
              'md:w-3/5',
              'lg:w-4/5',
            ])}
          >
            <div className="flex flex-col flex-1 justify-center items-center font-bold italic">
              <span
                className={classNames([
                  'font-heavent text-5xl text-center italic text-[rgb(204,17,14)] -mt-10 mb-4',
                  'xs:text-4xl',
                  'md:text-7xl',
                  'lg:text-5xl',
                ])}
              >
                ประกาศผลรายชื่อ
                <br />
                ผู้มีสิทธิ์ได้รับรางวัล
              </span>
              <span
                className={classNames([
                  'text-center text-[rgb(255,186,13)] text-6xl',
                  'xs:text-3xl xs:my-0',
                  'lg:text-4xl',
                ])}
              >
                ผู้ชนะอันดับที่ 1
              </span>
              <span
                className={classNames([
                  'text-4xl',
                  'xs:text-xl',
                  'md:text-4xl',
                  'lg:text-3xl',
                ])}
              >
                รับเงินรางวัล
              </span>
              <span
                className={classNames([
                  'text-center text-[rgb(255,186,13)] text-8xl -mt-2',
                  'xs:text-3xl xs:my-0',
                  'lg:text-6xl',
                ])}
              >
                1 แสนบาท
              </span>
              <span
                className={classNames([
                  'text-3xl text-center',
                  'xs:text-xl',
                  'md:text-4xl',
                  'lg:text-2xl',
                ])}
              >
                และลำดับที่ 2-60
                <br />
                รับหมวกจาก GDH X SCULPTURE
              </span>
            </div>
            <div className="w-full flex flex-col gap-4">
              {!isLogin && <LoginButtonContainer onLogin={onLogin} />}
              {isLogin && (
                <LeaderBoardButton
                  text="รายชื่อผู้มีสิทธิ์รับรางวัล"
                  onClick={onClick}
                />
              )}
              <TermsAndConditions href="/terms-and-conditions/?onback=/" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface LoginButtonContainerProps {
  onLogin: () => void;
}

const LoginButtonContainer = (props: LoginButtonContainerProps) => {
  const { onLogin } = props;

  return (
    <div className="text-center">
      <span className="italic block mb-2 opacity-50">
        กรุณา Log in เพื่อเช็กสิทธิ์รับของรางวัล
      </span>
      <button
        className="font-bold text-white rounded w-full pt-1 pb-3 bg-[rgb(25,119,242)] flex items-end justify-center"
        onClick={onLogin}
      >
        <div className="w-8 mr-2">
          <img alt="facebook-icon" src={FacebookIcon} />
        </div>
        <span className="text-2xl -mb-1">Login with Facebook</span>
      </button>
    </div>
  );
};

export default Landing;
