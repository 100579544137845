import { Wallpaper as wallpaper, WEBP } from '../assets/images';

import { classNames } from '../lib';

interface WallpaperProps {
  isBlur: boolean;
}

const Wallpaper = (props: WallpaperProps) => {
  const { isBlur } = props;

  return (
    <div
      className="absolute top-0 bg-[rgb(98, 8, 14)] w-full h-full z-10 animate-fade-up"
      style={{ filter: isBlur ? 'blur(5px)' : '' }}
    >
      <img
        className={classNames([
          'w-full h-full object-cover animate-from-right-xs-md',
          'lg:rounded-[40px]',
        ])}
        src={wallpaper}
        alt="wallpaper"
      />
      <div
        className={classNames([
          'bg-red-500 w-full h-full absolute bottom-0',
          'lg:rounded-[40px]',
        ])}
        style={{
          background:
            'linear-gradient(180deg, rgba(141,235,175,0) 70%, rgba(0,0,0,0.7987788865546218) 100%, rgb(0, 0, 0) 100%)',
        }}
      ></div>
    </div>
  );
};

export default Wallpaper;
