import { useEffect, useState } from 'react';

import type { SetStateAction } from '../../../types/hooks.types';

import useLeaderboard from '../../Leaderboard/useLeaderboard';
import Display from './Display';
import useCurrentRank from '../../Leaderboard/useCurrentRank';
import useFirstRank from '../../useFirstRank';
import useUser from '../../useUser';

import { Loading } from '../../../component';

interface IState {
  LOADING: 'LOADING';
  DISPLAY: 'DISPLAY';
}

type State = 'LOADING' | 'DISPLAY';

const STATE: IState = {
  LOADING: 'LOADING',
  DISPLAY: 'DISPLAY',
};

const Leaderboard = () => {
  const { loading: loadingFirstRank, firstRank } = useFirstRank();
  const { loading: loadingLeaderboard, leaderboard } = useLeaderboard();
  const { loading: loadingCurrentRank, currentRank } = useCurrentRank();
  const { loading: loadingUser, user } = useUser();

  const [state, setState]: [State, SetStateAction<State>] = useState<State>(
    STATE.LOADING,
  );

  useEffect(() => {
    if (
      !loadingFirstRank &&
      !loadingLeaderboard &&
      !loadingCurrentRank &&
      !loadingUser
    ) {
      setState(STATE.DISPLAY);
    }
  }, [loadingFirstRank, loadingLeaderboard, loadingCurrentRank, loadingUser]);

  // const mockMultipleFirstRank = [
  //   ...firstRank,
  //   ...firstRank,
  //   ...firstRank,
  //   ...firstRank,
  //   ...firstRank,
  // ];

  // const mockLeaderboard = [...Array(10)].map((_, i) => leaderboard[i]);

  const renderFromState = (state: State) => {
    switch (state) {
      case STATE.LOADING:
        return <Loading />;
      case STATE.DISPLAY:
        return (
          <Display
            firstRank={firstRank}
            leaderboard={leaderboard}
            me={user}
            currentRank={currentRank}
          />
        );
      default:
        return (
          <Display
            firstRank={firstRank}
            leaderboard={leaderboard}
            me={user}
            currentRank={currentRank}
          />
        );
    }
  };

  return renderFromState(state);
};

export default Leaderboard;
