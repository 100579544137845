import Form from './Form';
import Landing from './Landing';
import Leaderboard from './Leaderboard';

const Result = {
  Form,
  Landing,
  Leaderboard,
};

export default Result;
