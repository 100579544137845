import { useState, useEffect } from 'react';

import type { IUser } from '../../useUser';
import type { SetStateAction } from '../../../types/hooks.types';

import useUser from '../../useUser';
import useCurrentRank from '../../Leaderboard/useCurrentRank';

import { classNames } from '../../../lib';
import {
  APLogo,
  Button,
  Spotlight,
  UserFrame,
  Loading,
  TermsAndConditions,
  LeaderBoardButton,
} from '../../../component';
import { MainButton, SuccessIcon, Wallpaper } from '../../../assets/images';

import './index.css';

const STATE = Object.freeze({
  LOADING: 'LOADING',
  FORM: 'FORM',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
});

interface Information {
  name: string;
  lastname: string;
  email: string;
  telephone: string;
  address: string;
  isAcceptConsent: boolean;
}

interface ValidateInformation {
  name: boolean;
  lastname: boolean;
  email: boolean;
  telephone: boolean;
  address: boolean;
}

const InputType = Object.freeze({
  name: 'name',
  lastname: 'lastname',
  email: 'email',
  telephone: 'telephone',
  address: 'address',
  isAcceptConsent: 'isAcceptConsent',
});

const Form = () => {
  const { user } = useUser();
  const { loading, currentRank } = useCurrentRank();

  const [state, setState] = useState<string>(STATE.LOADING);

  const [information, setInformation] = useState<Information>({
    name: '',
    lastname: '',
    email: '',
    telephone: '',
    address: '',
    isAcceptConsent: false,
  });

  const [validates, setValidates] = useState<ValidateInformation>({
    name: false,
    lastname: false,
    email: false,
    telephone: false,
    address: false,
  });

  useEffect(() => {
    if (!loading) setState(STATE.FORM);
  }, [loading]);

  const onSubmit = () => {
    const submitData = async () => {
      try {
        const token: string = user.accessToken;
        const response = await fetch(
          process.env.REACT_APP_BACKEND_API + '/winner/submit',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              first_name: information.name.trim(),
              last_name: information.lastname.trim(),
              email: information.email.toLowerCase(),
              phone_number: information.telephone,
              address: information.address,
            }),
          },
        );
        const result = await response.json();

        if (response.status === 200) {
          if (result.reward) setState(STATE.SUCCESS);
        } else setState(STATE.FAILED);
      } catch (e) {
        console.error('❗', e);
        setState(STATE.FAILED);
      }
    };

    submitData();
  };

  const onInputChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;

    if (type === InputType.telephone && value.length > 10) return;

    setInformation({
      ...information,
      [type]: value,
    });

    if (type === InputType.email) {
      return setValidates({
        ...validates,
        [type]: validators.isEmail(value),
      });
    }

    if (type === InputType.telephone) {
      return setValidates({
        ...validates,
        [type]: validators.isTelephone(value),
      });
    }

    setValidates({
      ...validates,
      [type]: value.length > 0,
    });
  };

  const renderFromState = (state: string) => {
    switch (state) {
      case STATE.LOADING:
        return <Loading />;
      case STATE.FORM:
        return (
          <FormInput
            user={user}
            information={information}
            validates={validates}
            setInformation={setInformation}
            onInputChange={onInputChange}
            onSubmit={onSubmit}
          />
        );
      case STATE.SUCCESS:
        return <Success currentRank={currentRank.ranking} />;
      case STATE.FAILED:
        return (
          <FormInput
            user={user}
            information={information}
            validates={validates}
            setInformation={setInformation}
            onInputChange={onInputChange}
            onSubmit={onSubmit}
          />
        );
      default:
        return (
          <FormInput
            user={user}
            information={information}
            validates={validates}
            setInformation={setInformation}
            onInputChange={onInputChange}
            onSubmit={onSubmit}
          />
        );
    }
  };

  useEffect(() => console.log(validates), [validates]);

  return (
    <>
      <APLogo />
      <Spotlight />
      {state === STATE.SUCCESS && (
        <div
          className="absolute w-full h-full"
          style={{ filter: 'blur(5px) brightness(0.3)' }}
        >
          <img className="w-full h-full" alt="wallpaper" src={Wallpaper} />
        </div>
      )}
      <div
        className={classNames([
          'w-4/5 mx-auto flex flex-col h-full gap-6 relative z-20 py-5',
          'md:w-1/2',
          'lg:w-4/5',
        ])}
      >
        {renderFromState(state)}
      </div>
    </>
  );
};

interface FormInputProps {
  user: IUser;
  information: Information;
  validates: ValidateInformation;
  setInformation: SetStateAction<Information>;
  onInputChange: (type: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const FormInput = (props: FormInputProps) => {
  const {
    user,
    information,
    validates,
    setInformation,
    onInputChange,
    onSubmit,
  } = props;

  return (
    <>
      <div className="flex flex-col justify-center flex-1">
        <div className="flex flex-col items-center italic mb-5">
          <span
            className={classNames([
              'font-heavent font-bold text-4xl text-center',
              'xs:text-2xl',
            ])}
          >
            กรอกรายละเอียด
            <br />
            เพื่อยืนยันสิทธิ์รับรางวัล
          </span>
        </div>
        <div className="flex flex-col w-full bg-[rgb(51,51,51)] px-4 pt-6 relative border-cut-top">
          <div className="absolute bottom-0 left-0 h-[1rem] w-full bg-[rgb(51,51,51)] border-cut-bottom"></div>
          <div className="flex w-full">
            <div className="mt-[5px]">
              <UserFrame
                profilePicture={user.profilePicture}
                size="w-16 h-16"
                frame="4.5rem"
              />
            </div>
            <div className="flex flex-col w-full ml-5">
              <div className="-mt-1">
                <input
                  className="w-full"
                  type="text"
                  value={information.name}
                  placeholder="ชื่อ*"
                  onChange={e => onInputChange(InputType.name, e)}
                />
                <label
                  className={classNames([
                    'block text-[rgb(120,120,120)] opacity-80 text-sm',
                    'xs:text-xs',
                  ])}
                >
                  กรุณากรอกชื่อตามบัตรประชาชน
                </label>
              </div>
              <div className="mt-2">
                <input
                  className="w-full"
                  placeholder="นามสกุล*"
                  type="text"
                  value={information.lastname}
                  onChange={e => onInputChange(InputType.lastname, e)}
                />
                <label
                  className={classNames([
                    'block text-[rgb(120,120,120)] opacity-80 text-sm',
                    'xs:text-xs',
                  ])}
                >
                  กรุณากรอกนามสกุลตามบัตรประชาชน
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <input
                className="w-full"
                type="email"
                placeholder="อีเมล*"
                value={information.email}
                onChange={e => onInputChange(InputType.email, e)}
              />
              <label
                className={classNames(['text-sm text-dark-red', 'xs:text-xs'])}
                style={{
                  visibility:
                    information.email.length > 0 && !validates.email
                      ? 'visible'
                      : 'hidden',
                }}
              >
                รูปแบบอีเมลไม่ถูกต้อง
              </label>
            </div>
            <div className="mt-1 flex flex-col">
              <input
                className=""
                type="number"
                placeholder="เบอร์โทรศัพท์*"
                value={information.telephone}
                onChange={e => onInputChange(InputType.telephone, e)}
              />
              {information.telephone.length > 0 && !validates.telephone ? (
                <label
                  className={classNames([
                    'text-sm text-dark-red',
                    'xs:text-xs',
                  ])}
                >
                  หมายเลขโทรศัพท์ไม่ถูกต้อง
                </label>
              ) : (
                <label
                  className={classNames([
                    'text-[rgb(120,120,120)] opacity-80 text-sm',
                    'xs:text-xs',
                  ])}
                >
                  เช่น 081-234-5678
                </label>
              )}
            </div>
            <div className="mt-4 flex flex-col">
              <input
                className=""
                type="text"
                placeholder="ที่อยู่*"
                value={information.address}
                onChange={e => onInputChange(InputType.address, e)}
              />
              <label
                className={classNames([
                  'mb-4 text-[rgb(120,120,120)] opacity-80 text-sm',
                  'xs:text-xs',
                ])}
              >
                กรุณากรอกที่อยู่สำหรับจัดส่งของรางวัล
              </label>
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-4">
          <input
            className="mt-1"
            style={{
              accentColor: 'red',
            }}
            type="checkbox"
            checked={information.isAcceptConsent}
            id="consent-checkbox"
            name="consent-checkbox"
            onChange={() =>
              setInformation((prev: any) => ({
                ...prev,
                isAcceptConsent: !prev.isAcceptConsent,
              }))
            }
          />
          <label
            htmlFor="consent-checkbox"
            className={classNames(['text-sm', 'xs:text-xs'])}
          >
            ข้าพเจ้ายินยอมให้ บริษัท เอพี ไทยแลนด์ จำกัด มหาชน และบริษัทในเครือ
            <br />
            จัดเก็บใช้ และเปิดเผยข้อมูลที่ได้รับจากข้าพเจ้า เพื่อวัตถุประสงค์ใน
            <br />
            การติดต่อ และจัดส่งของรางวัลนี้ให้กับข้าพเจ้า
          </label>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        {validators.form(validates, information.isAcceptConsent) ? (
          <Button text="กดส่งข้อมูล" onClick={onSubmit} />
        ) : (
          <div className="relative flex justify-center items-center w-full">
            <div
              className={classNames([
                'h-16 w-full absolute cursor-not-allowed opacity-40',
                'xs:h-12',
              ])}
            >
              <img
                className="w-full h-full"
                alt="main-button"
                src={MainButton}
              />
            </div>
            <span
              className={classNames([
                'z-20 text-4xl italic cursor-not-allowed font-bold opacity-20',
                'xs:text-3xl',
              ])}
            >
              กดส่งข้อมูล
            </span>
          </div>
        )}
        <TermsAndConditions href="/terms-and-conditions/?onback=/form" />
      </div>
    </>
  );
};

interface SuccessProps {
  currentRank: number;
}

const WinnerPrize = Object.freeze({
  MONEY: 'MONEY',
  HAT: 'HAT',
});

const Success = (props: SuccessProps) => {
  const { currentRank } = props;

  const isWinner =
    currentRank === 1
      ? WinnerPrize.MONEY
      : currentRank <= 60
      ? WinnerPrize.HAT
      : null;

  const textWinner =
    isWinner === WinnerPrize.MONEY ? (
      <p
        className={classNames([
          'text-center text-2xl',
          'xs:text-xl',
          'md:text-4xl md:whitespace-nowrap',
          'lg:text-xl',
        ])}
      >
        เจ้าหน้าที่จะทำการติดต่อท่าน
        <br />
        ภายในวันที่ 10 พฤษภาคม 2565
        <br />
        เพื่อแจ้งรายละเอียดการเตรียมเอกสาร
        <br />
        <span className="md:-ml-4">
          ในการรับเงินรางวัลที่ 1 มูลค่า 100,000 บาท
        </span>
      </p>
    ) : (
      <p
        className={classNames([
          'text-center text-2xl',
          'xs:text-2xl',
          'md:text-4xl',
          'lg:text-2xl',
        ])}
      >
        เจ้าที่หน้าจะทำการจัดส่งของรางวัล
        <br />
        หมวกรุ่น BOMB ON THE BUS
        <br />
        ให้กับท่านภายใน 15 วันทำการ
      </p>
    );

  return (
    <>
      <div className="w-full h-full flex flex-col items-center italic py-5">
        <div className={classNames(['w-32', 'xs:w-20', 'md:w-40', 'lg:w-20'])}>
          <img alt="success-mark" src={SuccessIcon} />
        </div>
        <div className="w-full h-full flex flex-col">
          <div className="flex-1">
            <div
              className={classNames([
                'flex flex-col text-center mt-5 mb-2',
                'xs:mt-3',
                'lg:mt-3',
              ])}
            >
              <span
                className={classNames([
                  'font-bold text-4xl',
                  'xs:text-3xl',
                  'md:text-6xl',
                  'lg:text-4xl',
                ])}
              >
                เอพี ไทยแลนด์
              </span>
              <span
                className={classNames([
                  'font-bold text-3xl text-dark-red',
                  'xs:text-2xl',
                  'md:text-5xl',
                  'lg:text-3xl',
                ])}
              >
                ได้รับการยืนยันสิทธิ์ของท่าน
                <br />
                เรียบร้อยแล้ว
              </span>
            </div>
            {textWinner}
            <div className={classNames(['text-center mt-2', 'md:mt-5'])}>
              <span
                className={classNames([
                  'text-[rgb(255,241,212)]',
                  'md:text-2xl',
                  'lg:text-base',
                ])}
              >
                หลักฐานการลงทะเบียนสำเร็จจะส่งไปที่อีเมล์ที่คุณลงทะเบียนไว้
                <br />
                หรือสอบถามเพิ่มเติมทาง
                <br />
                AP THAI Facebook Messenger
              </span>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4">
            <LeaderBoardButton
              text="รายชื่อผู้มีสิทธิ์รับรางวัล"
              onClick={() => window.location.assign('/leaderboard')}
            />
            <TermsAndConditions href="/terms-and-conditions/?onback=/form" />
          </div>
        </div>
      </div>
    </>
  );
};

const validators = {
  isEmail: (email: string) =>
    /^[a-zA-Z0-9]+(\.)?[a-zA-Z0-9_]+\@[a-zA-Z]+\.[a-zA-Z]+/g.test(email),
  isTelephone: (telephoneNumber: string) =>
    /^0{1}[0-9]{9}$/g.test(telephoneNumber),
  form: (v: ValidateInformation, consent: boolean) =>
    Object.values(v).every(item => item) && consent,
};

export default Form;

// AP logo
// กรอกรายละเอียด
// form รูป ชื่อ นามสกุล เมล เบอร์ ที่อยู่
// tick radio
// a button
// term

// Send email
// Button to redirect to Leaderboard
