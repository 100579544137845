import { FacebookIcon, UserProfileFrame } from '../assets/images';

interface UserFrameProps {
  profilePicture: string;
  size: string;
  frame: string;
}

const UserFrame = (props: UserFrameProps) => {
  const { profilePicture, size, frame } = props;

  return (
    <div className="relative">
      <div className={`${size} relative z-10`}>
        <img
          className="w-full h-full object-cover rounded"
          src={profilePicture}
          alt="user-profile"
        />
        <div className="w-5 absolute -bottom-2 -right-2">
          <img alt="facebook-icon" src={FacebookIcon} />
        </div>
      </div>
      <div
        className="absolute -top-1 left-1/2"
        style={{ width: frame, transform: 'translate(-50%,0)' }}
      >
        <img alt="user-profile-frame" src={UserProfileFrame} />
      </div>
    </div>
  );
};

export default UserFrame;
