import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import type { SetStateAction } from './types/hooks.types';
import Landing from './pages/Landing';
import Login from './pages/Login';
import Game from './pages/Game';
import Leaderboard from './pages/Leaderboard';
import TermsAndConditions from './pages/TermsAndConditions';
import useAudio from './useAudio';
import AuthRouter from './routers';
import Finished from './pages/Finished';
import Result from './pages/Result';

import { Sound } from './component';
import { initFacebookSdk, classNames } from './lib';
import { BackgroundMusic } from './assets/sounds';
import { PhoneDesktopBackground } from './assets/images';
import { AnalyticsWrapper, useAnalytics } from './lib/google-analytics';
import useAudio_ from './useAudio_';

export type Auth = {
  authResponse: {
    accessToken: string;
    data_access_expiration_time: string;
    expiresIn: string;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  } | null;
  status: 'connected' | 'not_authorized' | 'unknown';
};

const PrivateRoute = ({ status, children }: any) => {
  if (status === 'connected') return children;
  return <Navigate to="/login" replace />;
};

const App = () => {
  const [loading, setLoading]: [boolean, SetStateAction<boolean>] =
    useState<boolean>(true);
  const [auth, setAuth]: [Auth, SetStateAction<Auth>] = useState<Auth>({
    authResponse: null,
    status: 'unknown',
  });
  // const { playing, toggle } = useAudio(BackgroundMusic);
  const { tracks, isMute, toggle } = useAudio_();
  // console.log(track.duration(1));

  useEffect(() => {
    localStorage.setItem('mute', 'true');
    const authenticate = async () => {
      const response: any = await initFacebookSdk();
      // console.log('authResponse', response);
      setAuth(response);
      setLoading(false);
    };

    authenticate();
  }, []);

  const { initialized } = useAnalytics();

  const _finishedAndWaitingForAnnounceRoute = (
    <BrowserRouter>
      <AnalyticsWrapper initialized={initialized}>
        <Routes>
          <Route path="/" element={<Finished />} />
        </Routes>
        <Routes>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
      </AnalyticsWrapper>
    </BrowserRouter>
  );

  const _announceAndFormRoutes = (
    <BrowserRouter>
      <AnalyticsWrapper initialized={initialized}>
        {!loading && (
          <Routes>
            <Route path="/" element={<Result.Landing auth={auth} />} />
            <Route
              path="leaderboard"
              element={
                <PrivateRoute
                  status={auth.status}
                  to={'/'}
                  children={<Result.Leaderboard />}
                />
              }
            />
            <Route
              path="form"
              element={
                <PrivateRoute
                  status={auth.status}
                  to={'/'}
                  children={<Result.Form />}
                />
              }
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </AnalyticsWrapper>
    </BrowserRouter>
  );

  const routers = (
    <BrowserRouter>
      <Sound isMute={isMute} toggle={toggle} />
      <AnalyticsWrapper initialized={initialized}>
        {!loading && (
          <Routes>
            <Route element={<AuthRouter auth={auth} />}>
              <Route path="/" element={<Landing auth={auth} />} />
              {auth.status !== 'connected' && (
                <Route path="login" element={<Login setAuth={setAuth} />} />
              )}
              <Route
                path="game"
                element={
                  <PrivateRoute
                    status={auth.status}
                    to={'/login'}
                    children={
                      <Game
                        tapSound={tracks.tapSound}
                        countdownStartSound={tracks.countdownStartSound}
                      />
                    }
                  />
                }
              />
              <Route
                path="leaderboard"
                element={
                  <PrivateRoute
                    status={auth.status}
                    to={'/login'}
                    children={<Leaderboard />}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        )}
      </AnalyticsWrapper>
    </BrowserRouter>
  );

  return (
    <>
      <div
        className={classNames([
          'h-full',
          'desktop-wrapper lg:relative lg:top-1/2 lg:left-1/2',
        ])}
      >
        <div
          className={classNames([
            'mobile-desktop hidden absolute top-1/2 left-1/2',
            'lg:flex justify-center',
          ])}
          style={{ transform: 'translate(-50%,-50%)' }}
        >
          <img
            className="overflow-hidden w-4/5"
            // if it broke you can use -> transform: scale(0.8);
            id="phone-desktop"
            alt="phone-desktop"
            src={PhoneDesktopBackground}
          />
        </div>
        <div
          id="app"
          className={classNames([
            'relative w-full h-full',
            'xs:h-screen',
            'lg:rounded-[40px] lg:w-[378px] lg:h-[656px] lg:overflow-hidden',
          ])}
        >
          {/* {routers} */}
          {/* {_finishedAndWaitingForAnnounceRoute} */}
          {_announceAndFormRoutes}
        </div>
      </div>
    </>
  );
};

export default App;
