import { useState } from 'react';

import type { SetStateAction } from '../../types/hooks.types';

import useUser from '../useUser';
import { useDidMountEffect } from '../../lib';

export interface CurrentRank {
  ranking: number;
  score: number;
}

const useCurrentRank = () => {
  const [currentRank, setCurrentRank]: [
    CurrentRank,
    SetStateAction<CurrentRank>,
  ] = useState<CurrentRank>({ ranking: 0, score: 0 });

  const { loading: loadingUser, user } = useUser();
  const [loading, setLoading]: [boolean, SetStateAction<boolean>] =
    useState<boolean>(true);

  useDidMountEffect(() => {
    const fetchCurrentRank = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/record/${user.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        },
      );
      const data = await response.json();

      setCurrentRank(data);
      setLoading(false);
    };

    fetchCurrentRank();
  }, [loadingUser]);

  return { currentRank, loading };
};

export default useCurrentRank;
