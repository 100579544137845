import ReactGA from 'react-ga4';

import type { IUser as IMe } from '../../../useUser';
import type { Leaderboard, IUser } from '../../../Leaderboard/useLeaderboard';
import type { CurrentRank } from '../../../Leaderboard/useCurrentRank';

import { classNames } from '../../../../lib';
import { FirstRank, FirstRankList, Row, Me, Skeleton } from '../Rows';
import {
  APLogo,
  Button,
  Spotlight,
  TermsAndConditions,
} from '../../../../component';

import './index.css';

interface DisplayProps {
  firstRank: Leaderboard;
  leaderboard: Leaderboard;
  currentRank: CurrentRank;
  me: IMe;
}

const WinnerPrize = Object.freeze({
  MONEY: 'MONEY',
  HAT: 'HAT',
});

const Display = (props: DisplayProps) => {
  const { firstRank, leaderboard, currentRank, me } = props;

  const newLeaderBoard: Leaderboard = [...new Array(199)].map(
    (_, i) => leaderboard[i],
  );

  const onClick = () => {
    ReactGA.event({
      category: 'engagement',
      action: 'click_play_again',
      label: 'Play Again',
    });
    window.location.assign('/form');
  };

  const isWinner =
    currentRank.ranking !== 0 &&
    (currentRank.ranking === 1
      ? WinnerPrize.MONEY
      : currentRank.ranking <= 60
      ? WinnerPrize.HAT
      : null);

  return (
    <div
      className={classNames([
        'w-4/5 mx-auto flex flex-col h-full gap-6 justify-between',
        'md:w-1/2',
        'lg:w-4/5',
      ])}
    >
      <APLogo />
      <Spotlight />
      <div
        className={classNames([
          'h-full flex flex-col relative z-20 justify-between pt-12 pb-10',
          'xs:pt-8',
          'lg:pt-8',
        ])}
      >
        <div className="flex flex-col items-center italic ">
          <span
            className={classNames([
              'font-heavent font-bold text-4xl',
              'xs:text-2xl',
            ])}
          >
            WINNERS
          </span>
          <span
            className={classNames([
              'font-heavent font-bold text-6xl text-dark-red -mt-3',
              'xs:text-4xl',
            ])}
          >
            RANKING
          </span>
          <p
            className={classNames([
              'text-center font-heavent font-light text-xl',
              'lg:-mt-3',
            ])}
          >
            ผู้ชนะอันดับ 1 ถึง 60 กรุณาลงทะเบียน
            <br />
            เพื่อยืนยันสิทธิ์ในการรับของรางวัลกลับ
            <br />
            <span className="whitespace-nowrap font-bold">
              ภายในวันที่ 9 พฤษภาคม 2565
            </span>
          </p>
        </div>
        <div className="flex flex-col h-1/2 flex-1 mt-4 relative">
          {firstRank.length === 1 && <FirstRank user={firstRank[0]} />}
          <div className="overflow-scroll flex-1 hidden-scrollbar">
            {firstRank.length > 1 &&
              firstRank.map((user: IUser) => (
                <FirstRankList key={user.user_id} user={user} />
              ))}
            {newLeaderBoard.map((user: IUser, index: number) =>
              user ? (
                <Row key={user.user_id} user={user} rank={index + 2} />
              ) : (
                <Skeleton key={index.toString()} index={index + 2} />
              ),
            )}
          </div>
        </div>
        <Me me={me} ranking={currentRank.ranking} score={currentRank.score} />
        <div
          className={classNames([
            'w-full flex flex-col gap-4 items-center mt-1',
            'xs:gap-1',
            'lg:gap-4',
          ])}
        >
          {!isWinner && (
            <div className="my-2">
              <span
                className={classNames([
                  'font-bold text-center text-[rgb(255,186,13)] text-2xl italic',
                  'xs:text-xl xs:my-0',
                  'md:text-3xl',
                  'lg:text-2xl',
                ])}
              >
                คุณไม่ติดอันดับ WINNER RANKING
              </span>
            </div>
          )}
          {isWinner === WinnerPrize.MONEY && (
            <div
              className={classNames([
                'w-full my-2 flex flex-col items-center',
                'xs:-mt-2 xs:mb-2',
                'lg:my-0',
              ])}
            >
              <span
                className={classNames([
                  'font-bold text-2xl text-center text-[rgb(255,186,13)] mb-4 italic',
                  'xs:text-xl xs:my-1',
                  'md:text-3xl',
                  'lg:text-2xl',
                ])}
              >
                ยินดีด้วยคุณได้รับเงินรางวัล{' '}
                {Number(
                  (100000 / firstRank.length).toFixed(0),
                ).toLocaleString()}{' '}
                บาท!
              </span>
              <Button text="ลงทะเบียนเพื่อยืนยันสิทธิ์" onClick={onClick} />
            </div>
          )}
          {isWinner === WinnerPrize.HAT && (
            <div
              className={classNames([
                'w-full my-2 flex flex-col items-center',
                'xs:-mt-2 xs:mb-2',
                'lg:my-0',
              ])}
            >
              <span
                className={classNames([
                  'font-bold text-2xl text-center text-[rgb(255,186,13)] mb-4 italic',
                  'xs:text-xl xs:my-1',
                  'md:text-3xl',
                  'lg:text-2xl',
                ])}
              >
                ยินดีด้วยคุณได้รับหมวก!
              </span>
              <Button text="ลงทะเบียนเพื่อยืนยันสิทธิ์" onClick={onClick} />
            </div>
          )}

          <TermsAndConditions href="/terms-and-conditions/?onback=/leaderboard" />
        </div>
      </div>
    </div>
  );
};

export default Display;
