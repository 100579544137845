import { useState, useEffect } from 'react';
import { Howl } from 'howler';

import {
  BackgroundMusic,
  CountdownStartSound,
  TapSound,
} from './assets/sounds';

const useAudio_ = () => {
  const [isMute, setIsMute] = useState(true);
  const [bgmusic] = useState(new Howl({ src: BackgroundMusic, autoplay: false, loop: true  }));
  const [countdownStartSound] = useState(
    new Howl({ src: CountdownStartSound }),
  );
  const [tapSound] = useState(new Howl({ src: TapSound }));

  useEffect(() => {
    if (isMute) {
      bgmusic.pause();
    } else {
      bgmusic.play();
    }
  }, [isMute]);

  const toggle = () => {
    setIsMute(!isMute);
    localStorage.setItem('mute', `${!isMute}`);
  };

  return {
    tracks: {
      countdownStartSound,
      tapSound,
    },
    isMute,
    toggle,
  };
};

export default useAudio_;
