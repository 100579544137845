import { ClickButtonSound } from '../assets/sounds';
import { LeaderBoardButton as LBtn } from '../assets/images';
import { classNames } from '../lib';

interface ButtonProps {
  text: string;
  onClick: () => void;
}

const LeaderBoardButton = (props: ButtonProps) => {
  const { text, onClick } = props;
  const sound: HTMLAudioElement = new Audio(ClickButtonSound);

  const _onClick = () => {
    try {
      const isMute = localStorage.getItem('mute') || 'false';
      if (!JSON.parse(isMute)) {
        sound.load();
        sound.play();
      }
      onClick();
    } catch (e) {
      console.error('❗', e);
    }
  };

  return (
    <div
      className="relative flex justify-center items-center"
      onClick={_onClick}
    >
      <div className="h-16 w-full absolute cursor-pointer">
        <img className="w-full h-full" alt="main-button" src={LBtn} />
        <div className="absolute w-[98%] h-[90%] top-[0.2rem] left-[0.25rem] bg-[rgba(202,138,0,0.5)] z-0 rounded-[1px]"></div>
      </div>
      <span
        className={classNames([
          'z-10 text-3xl italic font-bold cursor-pointer',
          'xs:text-2xl',
        ])}
      >
        {text}
      </span>
    </div>
  );
};
export default LeaderBoardButton;
