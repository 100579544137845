import { useEffect, useState } from 'react';

import type { SetStateAction } from '../../types/hooks.types';

export interface IUser {
  full_name: string;
  profile_picture: string;
  score: number;
  user_id: string;
}

export type Leaderboard = IUser[];

const useLeaderboard = () => {
  const [leaderboard, setLeaderboard]: [
    Leaderboard,
    SetStateAction<Leaderboard>,
  ] = useState<Leaderboard>([]);
  const [loading, setLoading]: [boolean, SetStateAction<boolean>] =
    useState<boolean>(true);

  useEffect(() => {
    const fetchLeaderBoard = async () => {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_API + '/record/top-ranking',
      );
      const data = await response.json();
      setLeaderboard(data);
      setLoading(false);
    };
    fetchLeaderBoard();
  }, []);

  return { loading, leaderboard };
};

export default useLeaderboard;
