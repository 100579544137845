import APLogo from './ap-logo.png';
import BackIcon from './back-icon.png';
import Betrayed from './kao-betrayed.png';
import CampaignLogo from './campaign-logo.png';
import YellowSpeed from './yellow-speed.png';
import RedSpeed from './red-speed.png';
import RunKaoRun from './run-kao-run.png';
import WhiteSpeed from './white-speed.png';
import FingerTap from './finger-tap.gif';
import FacebookIcon from './facebook-icon.png';
import MainButton from './main-button.png';
import TimeSign from './time-sign.png';
import LeaderBoardButton from './leaderboard-button.png';
import ShareIcon from './share-icon.png';
import Spotlight from './spotlight.png';
import UserProfileFrame from './user-profile-frame.png';
import FirstRankFrame from './first-rank-frame.png';
import NormalRankFrame from './normal-rank-frame.png';
import CrownIcon from './crown-icon.png';
import MyScoreFrame from './my-score-frame.png';
import ScoreBackground from './score-background.png';
import Wallpaper from './wallpaper.png';
import PlayerAnimate01 from './game/player-animate-01.png';
import PlayerAnimate02 from './game/player-animate-02.png';
import Countdown01 from './game/countdown-01.png';
import Countdown02 from './game/countdown-02.png';
import Countdown03 from './game/countdown-03.png';
import UserSkeleton from './user-skeleton-icon.png';
import PlaySoundIcon from './play-sound-icon.png';
import MuteSoundIcon from './mute-sound-icon.png';
import DesktopBackground from './desktop-background.png';
import PhoneDesktopBackground from './phone-desktop-background.png';
import CoinBackground from './coins-background.png';
import SuccessIcon from './success-icon.png';

// WEBP
import CampaignLogo_WEBP from './webp/campaign-logo.webp';
import DesktopBackground_WEBP from './webp/desktop-background.webp';
import Betrayed_WEBP from './webp/kao-betrayed.webp';
import PlayerAnimate01_WEBP from './webp/player-animate-01.webp';
import PlayerAnimate02_WEBP from './webp/player-animate-02.webp';
import RunKaoRun_WEBP from './webp/run-kao-run.webp';
import Spotlight_WEBP from './webp/spotlight.webp';
import TimeSign_WEBP from './webp/time-sign.webp';
import Wallpaper_WEBP from './webp/wallpaper.webp';

const WEBP = {
  campaignLogo: CampaignLogo_WEBP,
  desktopBackground: DesktopBackground_WEBP,
  kaoBetrayed: Betrayed_WEBP,
  playerAnimate01: PlayerAnimate01_WEBP,
  playerAnimate02: PlayerAnimate02_WEBP,
  runKaoRun: RunKaoRun_WEBP,
  spotlight: Spotlight_WEBP,
  timeSign: TimeSign_WEBP,
  wallpaper: Wallpaper_WEBP,
};

export {
  APLogo,
  BackIcon,
  Betrayed,
  CampaignLogo,
  FacebookIcon,
  FingerTap,
  ShareIcon,
  Spotlight,
  YellowSpeed,
  RedSpeed,
  RunKaoRun,
  WhiteSpeed,
  MainButton,
  TimeSign,
  UserProfileFrame,
  LeaderBoardButton,
  FirstRankFrame,
  NormalRankFrame,
  CrownIcon,
  MyScoreFrame,
  ScoreBackground,
  Wallpaper,
  PlayerAnimate01,
  PlayerAnimate02,
  Countdown01,
  Countdown02,
  Countdown03,
  UserSkeleton,
  PlaySoundIcon,
  MuteSoundIcon,
  DesktopBackground,
  PhoneDesktopBackground,
  CoinBackground,
  SuccessIcon,
  WEBP,
};
