import { Spotlight as Light, WEBP } from '../assets/images';

import { classNames } from '../lib';

const Spotlight = () => {
  return (
    <div
      className={classNames([
        'absolute top-0 left-0 w-full h-full z-10 overflow-hidden',
        'xs:h-screen',
      ])}
    >
      <picture className={classNames(['w-full h-full', 'lg:rounded-[40px]'])}>
        <source srcSet={WEBP.spotlight} />
        <img alt="spotlight" src={Light} />
      </picture>
    </div>
  );
};

export default Spotlight;
