import { useSearchParams, useNavigate } from 'react-router-dom';

import { APLogo, Wallpaper } from '../../component';
import { CampaignLogo, BackIcon, WEBP } from '../../assets/images';
import { classNames } from '../../lib';

const TermsAndConditions = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onBack = () => {
    const onBackUrl = searchParams.get('onback') || '/';
    navigate(onBackUrl, { replace: true });
  };

  return (
    <>
      <Wallpaper isBlur={true} />
      <div
        className={classNames([
          'bg-[rgba(0,0,0,0.6)] h-full relative z-10',
          'xs:h-screen',
          'lg:rounded-[40px]',
        ])}
      >
        <div className="h-full w-4/5 mx-auto py-5 flex flex-col items-center">
          <APLogo />
          <div className="w-40">
            <picture>
              <source srcSet={WEBP.campaignLogo} />
              <img alt="campaign-logo" src={CampaignLogo} />
            </picture>
          </div>
          <h1
            className={classNames([
              'font-bold text-4xl whitespace-nowrap italic mt-4 mb-2',
              'xs:text-3xl',
              'md:text-6xl',
              'lg:text-4xl',
            ])}
          >
            TERMS & CONDITIONS
          </h1>
          <div className="flex-1 overflow-scroll hidden-scrollbar">
            <p
              className={classNames([
                'font-heavent font-light text-lg italic text-[rgb(189,189,189)] px-[0.125rem]',
                'md:text-xl',
              ])}
            >
              1. ผู้ร่วมกิจกรรม (1 ท่าน) มีสิทธิ์รับของรางวัลได้เพียง 1 สิทธิ์
              ต่อ 1 ของรางวัลเท่านั้น
              <br />
              <br />
              2. รางวัลที่ 1 คือ เงินสดมูลค่า 100,000 บาท
              ในกรณีที่ผู้เข้าแข่งขันได้อันดับที่ 1 ร่วมกัน
              ทางบริษัทฯขอสงวนสิทธิ์ในการแบ่งเงินรางวัล 100,000 บาท
              นี้ให้กับผู้เข้าแข่งขันทั้งหมดโดยเท่ากัน ยกตัวอย่าง
              กรณีมีผู้เข้าแข่งขันได้อันดับ 1 ร่วมกัน 5 คน เงินรางวัล 100,000
              บาท จะถูกแบ่งให้ 5 คนเท่าๆ กัน เท่ากับได้คนละ 20,000 บาท เป็นต้น
              <br />
              <br />
              3. รางวัลที่ 2 คือ หมวกจาก AP x GDH X Sculpture มูลค่า 990 บาท
              (ไม่สามารถเปลี่ยนแปลงเป็นเงินสดได้) จำนวน 59 ใบ
              สำหรับผู้เข้าแข่งขันที่ได้ลำดับที่ 2 ถึง 60
              <br />
              <br />
              4. ช่วงเวลาเข้าร่วมสำหรับการแข่งขันนี้จะเริ่ม วันที่ 18 เมษายน
              2565 เวลา 00.01 น. และสิ้นสุด ในวันที่ 30 เมษายน 2565 เวลา 23.59
              น. ตามเวลาประเทศไทย โดยบริษัทฯจะประกาศผลหน้าเพจ Facebook AP Thai
              ภายในวันที่ 5 พฤษภาคม 2565
              และผู้ที่ได้รับสิทธิ์ต้องยืนยันสิทธิ์รับรางวัลกลับภายในวันที่ 9
              พฤษภาคม 2565 เวลา 13.00 น.
              <br />
              <br />
              5.
              บริษัทฯขอสงวนสิทธิ์ในการระงับหรือยกเลิกการให้รางวัลแก่บุคคลที่กระทำการใดๆที่ทุจริต
              หรือส่อเจตนาทุจริต หรือใช้โปรแกรมคอมพิวเตอร์
              หรือเครื่องมืออุปกรณ์อื่นๆ
              ใดที่มีลักษณะเดียวกันซึ่งทำให้ผู้เข้าแข่งขันอื่นเสียเปรียบ
              หรือไม่ตรงกับเงื่อนไขของกิจกรรมเพื่อรับของรางวัล
              <br />
              <br />
              6. ผู้จัดกิจกรรมขอสงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไข
              เพิ่มเติมเงื่อนไขในกติกาต่างๆ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
              <br />
              <br />
              7. ของรางวัลต่างๆ
              ที่มอบให้กับผู้ชนะรางวัลนั้นไม่สามารถโอนสิทธิ์ให้แก่ผู้อื่น
              และไม่สามารถเปลี่ยน หรือแลกเป็นเงินสด หรือของรางวัลอื่นได้
              <br />
              <br />
              8.
              ผู้ชนะการแข่งขันนี้เป็นผู้รับผิดชอบแต่เพียงผู้เดียวในการชำระภาษีและค่าธรรมเนียมตามกฎหมายใดๆ
              ขอสงวนสิทธิ์ที่จะหักภาษีและค่าธรรมเนียมต่างๆ ดังกล่าว ณ
              ที่จ่ายจากรางวัลตามขอบเขตที่กฏหมายระบุไว้
              <br />
              <br />
              9. พนักงานบริษัท เอพี ไทยแลนด์ และ
              บริษัทในเครือทั้งหมดไม่มีสิทธิ์ร่วมในการแข่งขันครั้งนี้
              <br />
              <br />
              10. การตัดสินของบริษัท เอพี ไทยแลนด์ จำกัด มหาชน
              ถือว่าเป็นที่สิ้นสุดในทุกกรณี
              <br />
              <br />
              11. ในกรณีผู้เล่นกดเล่นเกมหรือแชร์ ผ่าน Line อาจเกิดการ Error
              ต้องเปิดลิ้งค์เล่นผ่าน Browser เท่านั้น เช่น Safari, Google Chrome
            </p>
          </div>
          <div className="my-5 p-2 flex items-center" onClick={onBack}>
            <div className="w-4 mr-2">
              <img alt="back-icon" src={BackIcon} />
            </div>
            <button className="text-2xl italic text-[rgb(255,241,212)] px-1">
              ย้อนกลับ
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
