export const format = (time: number): string => {
  const minutes: number = Math.floor(time / (1000 * 60));
  const seconds: string = Math.floor((time / 1000) % 60)
    .toString()
    .padStart(2, '0');
  const milliseconds: string = (time % 1000).toString().padStart(3, '0');
  const formatTime: string = `${minutes}:${seconds}.${milliseconds}`;

  return formatTime;
};
