import { useState, useEffect } from 'react';

import type { SetStateAction } from '../types/hooks.types';

export interface IUser {
  accessToken: string;
  name: string;
  profilePicture: string;
  userId: string;
}

const useUser = () => {
  const [loading, setLoading]: [boolean, SetStateAction<boolean>] =
    useState<boolean>(true);
  const [user, setUser]: [IUser, SetStateAction<IUser>] = useState<IUser>({
    accessToken: '',
    name: '',
    profilePicture: '',
    userId: '',
  });

  useEffect(() => {
    const user: string = localStorage.getItem('user') || '';

    try {
      const userObj = JSON.parse(user);
      setUser(userObj);
      setLoading(false);
    } catch (e) {
      window.FB.logout();
    }
  }, []);

  return { loading, user };
};

export default useUser;
