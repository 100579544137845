import { useState } from 'react';
import { useDidMountEffect } from '../lib';

import type { SetStateAction } from '../types/hooks.types';

import useUser from './useUser';

export interface IFirstRank {
  full_name: string;
  profile_picture: string;
  score: number;
  user_id: string;
}

type FirstRank = IFirstRank[];

const useFirstRank = () => {
  const [loading, setLoading]: [boolean, SetStateAction<boolean>] =
    useState<boolean>(true);
  const [firstRank, setFirstRank]: [FirstRank, SetStateAction<FirstRank>] =
    useState<FirstRank>([]);

  const { loading: loadingUser, user } = useUser();

  useDidMountEffect(() => {
    try {
      const token = user.accessToken;
      const fetchData = async () => {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_API + '/record/first-place',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const data = await response.json();
        setFirstRank(data);
        setLoading(false);
      };
      fetchData();
    } catch (e) {
      console.error('❗', e);
    }
  }, [loadingUser]);

  return { loading, firstRank };
};

export default useFirstRank;
